export enum Size {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl'
}

export type Magnitude = 1 | 2 | 3 | 4 | 6 | 8 | 12 | 16 | 24 | 32;

export interface WithSize<T> {
  [Size.ExtraSmall]?: T;
  [Size.Small]?: T;
  [Size.Medium]?: T;
  [Size.Large]?: T;
  [Size.ExtraLarge]?: T;
}
