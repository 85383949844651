import { DOMAIN } from '@/config';

export const getAbsoluteUrl = (path: string) => `${DOMAIN}${path}`;

const generatePath = (url: string, object: { [x: string]: string | number }) => {
  return Object.keys(object).reduce((url, key) => url.replace(`[${key}]`, object[key] as string), url);
};

const get = (href: string, object: { [x: string]: string | number }) => ({
  href,
  as: generatePath(href, object)
});

export const home = `/`;
export const work = `/work`;
export const contact = `/contact`;

export const blog = `/blog`;
export const blogCategory = (tag: string) => get(`${blog}/categories/[tag]`, { tag });
export const blogPost = (slug: string) => get(`${blog}/[slug]`, { slug });

export const legal = (slug: 'terms-and-conditions' | 'privacy-policy' | 'cookie-policy') => get(`/[slug]`, { slug });

export const cv = `/resume-2020-07-12.pdf`;

export const linkedin = `https://www.linkedin.com/in/botond-veress-781a2495`;
export const github = `https://github.com/botond-veress`;
export const email = `hello@botondveress.com`;
