export const DOMAIN = process.env.DOMAIN!;
export const API = process.env.API!;
export const PRISMIC_REPOSITORY = process.env.PRISMIC_REPOSITORY!;
export const PRISMIC_ACCESS_TOKEN = process.env.PRISMIC_ACCESS_TOKEN!;
export const PRISMIC_PREVIEW = !!process.env.PRISMIC_PREVIEW;

export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;
export const DISQUS_SHORTNAME = process.env.DISQUS_SHORTNAME;
export const GTM_CONTAINER_ID = process.env.GTM_CONTAINER_ID;
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;
export const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
